import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  ReferenceInput,
  Pagination,
  AutocompleteInput,
  TextInput,
  SelectInput,
  RecordContextProvider,
  DatagridBody,
  useRecordContext,
} from "react-admin";
import { TableCell, TableRow, Checkbox } from "@mui/material";

const MyDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => (
  <RecordContextProvider value={record}>
    <TableRow>
      <TableCell padding="none">
        <Checkbox
          disabled={selectable}
          checked={selected}
          onClick={(event) => onToggleItem(id, event)}
        />
      </TableCell>
      {React.Children.map(children, (field) => (
        <TableCell
          key={`${id}-${field.props.source}`}
          style={
            field.props.source == "schulnote" && record.id == "Schulnote Avg"
              ? {
                  background: "green",
                  color: "#fff",
                }
              : record.id == "Schulnote Avg" 
              ? {
                  background: "green",
                  color: "#fff",
                }
              : {}
          }
        >
          {field}
        </TableCell>
      ))}
    </TableRow>
  </RecordContextProvider>
);
const MyDatagridBody = (props) => (
  <DatagridBody {...props} row={<MyDatagridRow />} />
);
const MyDatagrid = (props) => {
  return (
    <Datagrid {...props} body={<MyDatagridBody />} />
  );
};


const PerformBewertungPagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} {...props} />
);
const yearFilter = () => {
  const curYear = parseInt(new Date().getFullYear());
  const yearLimit = [curYear - 10, curYear + 10];
  let years = [];
  for (let i = yearLimit[0]; i <= yearLimit[1]; i++) {
    years.push({ id: i, name: i });
  }
  return years;
};
export const PerformBewertungList = () =>   (
    <List 
    filters={performBewertungFilters}      
    perPage={50}
    pagination={<PerformBewertungPagination />}
  >
    <MyDatagrid>
      <TextField source="id" />
      <ReferenceField source="pid" label="Project" reference="projects" />
      <ReferenceField 
        source="zustandig" 
        reference="workers" 
        label="Zuständig"
      />
      <TextField source="umsatz" />
      <TextField source="deckungsbeitrag" />
      <PercentageField label="%" source="percentage"/>
      <TextField source="kommentar" />
      <TextField source="schulnote" />
      <TextField source="complain_list" label="Beschwerde" />
      <TextField source="auftragseingang" label="Auftragseingang" />
    </MyDatagrid>
  </List>
);
const PercentageField = ({  source }) => {
  console.log(source)
  const record = useRecordContext();
  return (record.id != 'Schulnote Avg' && <span>{record[source]}%</span>); 
};
const performBewertungFilters = [
  <TextInput label="Search Kommentar" source="kommentar" alwaysOn />,
  <ReferenceInput
    source="workers"
    reference="workers"
    alwaysOn
  >
    <AutocompleteInput label="Zustandings" />
  </ReferenceInput>,
  <SelectInput
    source="month"
    label="Month"
    alwaysOn
    choices={[
      { id: "01", name: "January" },
      { id: "02", name: "February" },
      { id: "03", name: "March" },
      { id: "04", name: "April" },
      { id: "05", name: "May" },
      { id: "06", name: "June" },
      { id: "07", name: "July" },
      { id: "08", name: "August" },
      { id: "09", name: "September" },
      { id: "10", name: "October" },
      { id: "11", name: "November" },
      { id: "12", name: "December" },
    ]}
  />,
  <SelectInput source="year" label="Year" alwaysOn choices={yearFilter()} />,
  <ReferenceInput
    source="complaintCategories"
    alwaysOn
    reference="complaintCategories"
  >
    <AutocompleteInput label="Beschwerde" optionText="name" />
  </ReferenceInput>
];
